import { api } from '@/utils/fetch'

/** 查询简单行业动态/曝光台列表 */
// const querySimpleIndustryDynamics = params => api('/index/querySimpleIndustryDynamics.do', params)

/** 查询政策法规列表 */
const querySimpleLaws = params => api('/index/querySimpleLaws.do', params)

/** 查询行业动态/曝光台详情 */
// const queryIndustryDynamicDetails = params => api('/index/getIndustryDynamicDetails.do', params, 'get')

/** 查询政策法规详情 */
const queryGetLawDetails = params => api('/index/getLawDetails.do', params, 'get')

/** 查询全部区域列表 */
// const queryIndexOrignList = params => api('/index/indexOrignList.do', params)

/** 查询驾校列表||首页驾校查询 */
// const queryTrainingSchoolList = params => api('/index/queryTrainingSchoolList.do', params)





/** 查询驾校教练员详情 */
// const queryQueryList = params => api('/coach/manage/queryList.do', params, 'get')




/** 首页驾培统计 */
// const queryIndexCollectList = params => api('/index/indexCollectList.do', params, 'post', false)

/** 查询体检地址列表 */
const queryGetHospitalList = params => api('/index/getHospitalList.do', params, 'get')

/** 查询体检地址列表无loading */
const queryNoLoadingGetHospitalList = params => api('/index/getHospitalList.do', params, 'get', false)


/** 学车须知或者驾驶常识列表接口 */
const queryEssayList = params => api('/ar/essay/essayList.do', params, 'get')

/** 学车须知或者驾驶常识详情接口 */
const queryEssayDetail = params => api('/ar/essay/essayDetail.do', params, 'get')

/** 首页评价 */
// const queryHomeEvaluate = params => api('/index/evaluate', params, 'get')

/** 获取章节目录 */
const queryGetChapterList = params => api('/index/theoryCenter/getChapterList', params, 'get')

/** 获取模拟考试题 */
const queryGetMockExam = params => api('/index/theoryCenter/getMockExam', params, 'get')

/** 通过条件获取题目ID列表 */
const queryGetQuestionIdList = params => api('/index/theoryCenter/getQuestionIdList', params, 'get')

/** 通过id获取题目 */
const queryGetQuestionById = params => api('/index/theoryCenter/getQuestionById', params, 'get')

/**  */
const getTestCenterList = params => api('/index/theoryCenter/getTestCenterList', params, 'get')
//http://localhost:9100/gzpt/index/theoryCenter/getTestCenterList?carType=car&stepType=1

/** 公示栏 */
const queryPublicNotice = params => api('/index/queryPublicNotice.do', params, 'post', false)
/** 公示栏详情 */
const getPublicNoticeByDetails = params => api('/index/getPublicNoticeByDetails.do', params, 'post', false)


// 洛阳接口
// 优质驾校
const recommendSchoolList1 = params => api('/website/luoyang/recommendSchoolList1', params, 'get')
// 明星教练
const recommendCoachList = params => api('/website/luoyang/recommendCoachList', params, 'get')
// 洛阳市区
const queryIndexOrignList = params => api('/website/luoyang/luoyangList', params, 'get')
// 驾照类型
const licenseType = params => api('/website/luoyang/licenseType', params, 'get')
// 官网驾校
const queryTrainingSchoolList = params => api('/website/luoyang/getSchoolListWebPage', params, 'get')
// 洛阳实时数据
const queryIndexCollectList = params => api('/website/luoyang/getRealDataTotal', params, 'get')
// 驾培行业服务综合评价
const queryHomeEvaluate = params => api('/website/luoyang/getCommentDetail', params, 'get')
// 教练列表
const queryQueryList = params => api('/website/luoyang/getCoachListWebPage', params, 'get')
// 获得行业资讯-政策管理
const querySimpleIndustryDynamics = params => api('/website/luoyang/articlezcWebsite', params, 'get')
// 获得行业资讯-政策管理-详情
const queryIndustryDynamicDetails = params => api('/website/luoyang/getarticleWebsite', params, 'get')
/** 创建投诉咨询 */
const queryCreateComplaint = params => api('/website/luoyang/createconsultWebsite', params, 'post')
/** 根据区域分组查询驾校数量 */
const queryIndexSchoolGroupList = params => api('/website/luoyang/getDistricetSchoolList', params, 'post')
/** 驾校详情 */
const queryGetSchoolDetailById = params => api('/website/luoyang/getselectDetailOne', params, 'get')
/** 根据驾校查询班型列表 */
const queryTrainingClasss = params => api('/website/luoyang/getSchoolClassListWebPage', params, 'get')
/** 查询驾校场地图片列表 */
const queryGetSiteBySchoolId = params => api('/website/luoyang/getTrainingSitePage', params, 'post')
// 驾校详情里的统计车辆与教练数
const getWebsiteSchoolDetailRespVO = params => api('/website/luoyang/getWebsiteSchoolDetailRespVO', params, 'get')
/** 地图驾校搜索 */
const queryGetSchoolByName = params => api('/website/luoyang/getWebsiteSchoolMapList', params, 'get', )










export {
  querySimpleIndustryDynamics,
  queryIndustryDynamicDetails,
  querySimpleLaws,
  queryGetLawDetails,
  queryIndexOrignList,
  queryTrainingSchoolList,
  queryGetSchoolDetailById,
  queryGetSiteBySchoolId,
  queryQueryList,
  queryTrainingClasss,
  queryGetSchoolByName,
  queryCreateComplaint,
  queryIndexCollectList,
  queryGetHospitalList,
  queryNoLoadingGetHospitalList,
  queryIndexSchoolGroupList,
  queryEssayList,
  queryEssayDetail,
  queryHomeEvaluate,
  queryGetChapterList,
  queryGetMockExam,
  queryGetQuestionIdList,
  queryGetQuestionById,
  getTestCenterList,
  queryPublicNotice,
  getPublicNoticeByDetails,

  recommendSchoolList1,
  recommendCoachList,
  getWebsiteSchoolDetailRespVO,
}
