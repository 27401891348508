/* eslint-disable */
/**
 *  获取url上的参数
 * @param {string} name 
 */
export const getUrlParam = function (name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURI(r[2])
  return null
}

/**
 * 添加对应名称的cookie
 * @param name cookie的名称
 * @param value cookie的值 
 * @returns {null} 不存在时，返回null
 */
export const setCookie = function (name, value) {
  clearCookie(name);
  if (name && value) {
    var cookie = name + '=' + value;
    document.cookie = cookie;
    return cookie;
  } else {
    return null;
  }
}

/**
 * 获取对应名称的cookie
 * @param name cookie的名称
 * @returns {null} 不存在时，返回null
 */
export const getCookie = function (name) {
  let arr;
  let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)")
  if (arr = document.cookie.match(reg))
    return unescape(arr[2]);
  else
    return null;
}

/**
 * 清除特定name的cookie
 */
export const clearCookie = function (name) {
  var exp = new Date()
  exp.setTime(exp.getTime() - 1)
  var cval = getCookie(name)
  if (cval != null) { document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString() }
}
/**
 * 生成随机字符串
*/
export const randomStr = function (len) {
  var result = '',
    charts = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  len = len || 20
  while (len--) {
    result += charts[Math.ceil(Math.random() * charts.length - 1)]
  }
  return result
}
/** 生成guid */
export const createUuid = function () {
  var d = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  // localStorage.setItem("uuid", uuid)
  setCookie('uuid', uuid)
  console.log(uuid)
  return uuid
}
/**
 * 清除 所有cookie
 */
export const clearAllCookie = function (name) {
  var keys=document.cookie.match(/[^ =;]+(?=\=)/g); 
  if (keys) { 
  for (var i = keys.length; i--;) 
  document.cookie=keys[i]+'=0;expires=' + new Date( 0).toUTCString() 
  } 
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export const formatTime = function (date) {
  let data = new Date(date);
  const year = data.getFullYear()
  const month = data.getMonth() + 1
  const day = data.getDate()
  const hour = data.getHours()
  const minute = data.getMinutes()
  const second = data.getSeconds()

  // return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
  return [year, month, day].map(formatNumber).join('/')
}
