
// 新接口
import axios from 'axios'
import { Loading } from 'element-ui';
import Encode from './encode'
import { getCookie, createUuid } from '@/utils/common'
import { Message } from 'element-ui'

export const api = function (url, body, method, loading = true, platform, buriedPointReset) {
  // const data = {
  //   'brand': '',
  //   'guid': getCookie('uuid') ? getCookie('uuid') : createUuid(), // 必传
  //   'model': '',
  //   'platform': platform || 'PC',
  //   'sysVersion': '',
  //   'time': parseInt(+new Date() / 1000),
  //   'uid': getCookie('uid') ? getCookie('uid') : 0, // 必传
  //   'version': '4.8.0',
  //   'session': getCookie('session') ? getCookie('session') : '',
  //   'body': body
  // }
  // if (buriedPointReset) {
  //   data.buriedPointReset = buriedPointReset
  // }
  // console.log(`%c 请求：${url}`, 'color: blue;font-size: 16px', data)
  const data = body
  let loadings = undefined
  console.log(`%c 请求：${url}`, 'color: blue;font-size: 16px', body)
  // let sendData = Encode.encodeFunc(data)
  let sendData = data
  if (loading) {
    loadings = Loading.service({
      lock: true,
      text: 'Loading',
      // spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.8)'
    })
  }
  return new Promise((resolve, reject) => {
    axios({
      method: method || 'post',
      // url: process.env.VUE_APP_URL + '/api' + url, // 上线前需要修改
      url: '/app-api' + url,
      // url: '/gzpt' + url,//正式的
      // headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: sendData,
      params: method && method == 'get' ? sendData : {}
    })
      .then((res) => {
        console.log(res)
        if (loading) loadings.close()
        let getData = res.data
        // let decodeGetData = JSON.parse(Encode.decodeFuc(getData.data)) // 解密data   获取的是字符串
        // let toAes = Encode.encodeFunc(decodeGetData) // 对解密的数据加密
        console.log(`%c 响应：${url}`, 'color: green;font-size:16px;', getData)
        if (getData.code === 0) {
          resolve(getData)
        } else {
          const timeError = {
            msg: getData.message,
            data: getData.data,
            code: getData.code
          }
          // Message({
          //   message: timeError.msg,
          //   type: 'error',
          //   duration: 5 * 1000
          // })
          reject(timeError)
        }
      })
      .catch((err) => {
        console.log(err)
        if (loading) loadings.close()
        let error = {
          msg: '服务器错误'
        }
        console.debug(err)
        reject(error)
      })
  })
}
